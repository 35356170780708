/**
 *  Personal Site: My humble personal homepage, made with a tiny bit but not much care.
 *  <https://github.com/MattIPv4/Personal-Site/>
 *  Copyright (C) 2023 Matt Cowley (MattIPv4) (me@mattcowley.co.uk)
 *
 *  This program is free software: you can redistribute it and/or modify
 *   it under the terms of the GNU General Public License as published
 *   by the Free Software Foundation, either version 3 of the License, or
 *   (at your option) any later version.
 *  This program is distributed in the hope that it will be useful,
 *   but WITHOUT ANY WARRANTY; without even the implied warranty of
 *   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *   GNU General Public License for more details.
 *  You should have received a copy of the GNU General Public License
 *   along with this program. If not, please see
 *   <https://github.com/MattIPv4/Personal-Site/blob/master/LICENSE.md> or <http://www.gnu.org/licenses/>.
 */

@use "sass:color";

$command: #00aba4;
$text: #dc3b3b;
$comment: #52bc57;
$function: #8884c5;
$scheme: #d18e62;

html,
body {
  background: #292b37;
}

.content {
  h1 {
    margin: .5em 0 .75em;

    &::before {
      content: "👋 Hello, I'm ";
    }

    &::after {
      animation: blink 1.2s linear infinite;
      content: ". ";

      @keyframes blink {
        0% {
          content: ". ";
        }

        50% {
          content: ". ";
        }

        50.1% {
          content: ".|";
        }

        100% {
          content: ".|";
        }
      }
    }
  }

  h2,
  h3:not(.featured) {
    color: $text;
    font-size: 1.5em;
    margin: 25px 0;

    &::before {
      color: $command;
      content: "print(\"";
    }

    &::after {
      color: $command;
      content: "\")";
    }
  }

  h4,
  h5,
  h3.featured {
    font-size: 1.35em;
  }

  h4,
  h5,
  h3.email,
  h3.featured {
    margin: 45px 0 25px;
  }

  h3.featured,
  h4,
  h5,
  .contact li a i,
  footer p {
    color: $comment;
    text-decoration: none;

    &::before {
      content: "/* ";
    }

    &::after {
      content: " */";
    }
  }

  h3.email {
    a {
      &::before {
        color: $scheme;
        content: "mailto:";
      }
    }
  }

  a,
  a:visited {
    color: $function;
  }

  a:focus,
  a:hover {
    color: color.adjust($function, $lightness: -10%);
  }

  .contact {
    flex-direction: column;

    &::before,
    &::after {
      color: $command;
      font-size: 1.5em;
    }

    &::before {
      content: "links([";
    }

    &::after {
      content: "])";
    }

    li {
      font-size: 1.5em;
      margin: 0 0 0 1em;

      &:not(:last-child) {
        @media only screen and (max-width: 768px) {
          margin-top: .25em;
        }
      }

      a {
        text-decoration: none;

        &::before {
          color: $scheme;
          content: "link:";
        }

        &::after {
          color: $command;
          content: ",";
        }

        br {
          display: none;

          @media only screen and (max-width: 768px) {
            display: initial;
          }
        }

        i {
          margin: 0 0 0 .5em;
        }
      }
    }
  }
}
